<template>
    <div 
        ref="tableWrapper" 
        class="flex-grow">
        <a-table
            :ref="tableRef"
            :scroll="scroll"
            :loading="loading"
            :columns="columnsVisible"
            @change="handleTableChange"
            :expandedRowKeys="expandedRowKeys"
            :expandIconColumnIndex="1"
            :size="tableSize"
            :pagination="false"
            :locale="{
                emptyText: $t('task.no_data'),
            }"
            :data-source="rowList"
            :row-key="(record, index) => `${record.id}-${index}`">
            <div 
                v-for="column in columnsVisible"
                :key="column.key"
                :slot="column.key" 
                slot-scope="text, record, expanded, indent">
                <WidgetSwitch
                    :column="column"
                    :text="text"
                    :record="record"
                    :model="model"
                    :tableType="tableType"
                    :pageName="pageName"
                    :openHandler="openHandler"

                    :taskType="taskType"
                    :takeTask="takeTask"
                    :startEdit="startEdit" 
                    :deleteSprint="deleteSprint" 
                    :updateStatus="updateStatus" 
                    :main="main"
                    :expandedRowKeys="expandedRowKeys"
                    :extendDrawer="extendDrawer"
                    :reloadTask="reloadTask"
                    :showChildren="showChildren"
                    :expanded="expanded"
                    :indent="indent"
                    :getInvoicePayment="getInvoicePayment"
                    
                    :openModalStat="openModalStat"
                    :openDescModal="openDescModal" />
            </div>
        </a-table>
        <div class="flex justify-end items-center mt-1">
            <div 
                v-if="hasData && tableInfo"
                class="mr-2">
                <a-modal
                    @cancel="settingsVisible = false"
                    :visible="settingsVisible">
                    <div class="columns_checkbox_group flex flex-col">
                        <div class="mb-2 font-semibold">
                            Настройки таблицы
                        </div>
                        <div class="">
                            <div class="mb-2 pb-2 border-b">
                                <div class="mb-1 font-medium">Размер</div>
                                <a-radio-group 
                                    :value="tableSize" 
                                    @change="changeTableSize">
                                    <a-radio 
                                        v-for="option in tableSizeOptions"
                                        :key="option.value"
                                        :value="option.value">
                                        {{ option.label}}
                                    </a-radio>
                                </a-radio-group>
                            </div>
                            <div>
                                <div class="mb-2 flex justify-between font-medium">
                                    <span>Столбцы</span>
                                    <span>Фиксация</span>
                                </div>
                                <a-checkbox 
                                    ref="columnsCheckbox"
                                    class="mb-1 pb-1 border-gray-300"
                                    :indeterminate="indeterminate" 
                                    :checked="checkAllColumns" 
                                    @change="onCheckAllChange">
                                    Все
                                </a-checkbox>
                            </div>
                        </div>
                        <a-checkbox-group 
                            v-model="columnsActive"
                            @change="onColumnCheckboxChange">
                            <a-row
                                v-for="column in columnOptions"
                                :key="column.value"
                                class="flex mt-1 first:mt-0">
                                <CheckboxColumn
                                    :tableName="model+pageName"
                                    :setColumnName="setColumnName"
                                    :tableColumns="tableColumns"
                                    :column="column" />
                            </a-row>
                        </a-checkbox-group>
                    </div>
                    <template #footer>
                        <div class="flex">
                            <a-button 
                                class="mr-2"
                                type="ui"
                                block
                                @click="dropColumns(); settingsVisible = false">
                                По умолчанию
                            </a-button>
                            <a-button 
                                block
                                type="primary"
                                @click="setColumns(); settingsVisible = false">
                                Подтвердить
                            </a-button>
                        </div>
                    </template>
                </a-modal>
            </div>
            <Pager
                v-if="showPager && hasData && tablePageSize && rowCount > tablePageSize"
                ref="Pager"
                :hash="hash"
                :changePage="changePage"
                :changeSize="changePageSize"
                :pageSize="tablePageSize"
                :page="page"
                :scrollElements="[
                    '.task_table .ant-table-body-inner',
                    '.task_table .ant-table-body'
                ]"
                :count="rowCount" />

        </div>
    </div>
</template>

<script>
import WidgetSwitch from './WidgetSwitch.vue'
import Pager from './Pager'
import CheckboxColumn from './CheckboxColumn.vue'
import eventBus from '@/utils/eventBus'
import { mapActions } from 'vuex'
export default {
    components: {
        WidgetSwitch,
        CheckboxColumn,
        Pager,
    },
    props: {
        /* ___ ОБЯЗАТЕЛЬНЫЕ ВХОДНЫЕ ПАРАМЕТРЫ ___ */

        /* Нужен для получения дефолтных настроек. Может быть:
            tasks,
            logistic,
            orders,
            chat_tasks,
            interests,
            projects,
            groups,
            meetings,
            sprints,
            analytics */ 
        tableType: {
            type: String,
            required: true
        },
        /* Связываясь с pageName образует ключ, 
            под которым настройки таблицы хранятся в сторе */
        model: {
            type: String,
            required: true
        },
        /* Связываясь с model образует ключ, 
            под которым настройки таблицы хранятся в сторе */
        pageName: {
            type: String,
            required: true
        },      
        /* Функция обновления списка записей.
            Используется при изменении сортировки, размера страницы и т.п.  */
        updateData: {
            type: Function,
            required: true
        },
        /* Предикат состояния загрузки данных */
        loading: {
            type: Boolean,
            required: true
        },
        /* Текущая страница таблицы. Нужная для пагинатора */
        page: {
            type: Number,
            required: true
        },
        /* Высота таблицы */
        scrollX: {
            type: [Number, Boolean],
            default: false
        },
        /* Объект должен содержать поле page. Нужно для синхронизации с пагинатором */
        // reactiveOptions: {
        //     type: Object,
        //     required: true    
        // },
        changePage: {
            type: Function,
            required: true
        },

        /* ___ НЕ ОБЯЗАТЕЛЬНЫЕ ВХОДНЫЕ ПАРАМЕТРЫ ___ */
        /* Список данных для таблицы */
        rowList: {
            type: Array,
            default: () => []
        },  
        /* Кол-во страниц с данными. Нужно для пагинатора */
        rowCount: {
            type: Number,
            default: null
        },
        /* Функция открытия записи.
            Например, открытие задачи */
        openHandler: {
            type: Function,
            default: () => {}
        },
        showPager: {
            type: Boolean,
            default: true
        },

        taskType: { type: String, default: ''},
        main: {
            type: Boolean,
            default: false
        },
        getInvoicePayment: {
            type: Function,
            default: () => {}
        },
        showChildren: Boolean,
        reloadTask: {
            type: Function,
            default: () => {}
        },
        extendDrawer: {
            type:  Boolean,
            default: false
        },
        hash: {
            type:  Boolean,
            default: false
        },
        startEdit: {
            type: Function,
            default: () => {}
        },
        deleteSprint: {
            type: Function,
            default: () => {}
        },
        updateStatus: {
            type: Function,
            default: () => {}
        },

        openDescModal: {
            type: Function,
            default: () => {}
        },
        openModalStat: {
            type: Function,
            default: () => {}
        },
        takeTask: {
            type: Function,
            default: () => {}    
        }
    },
    data() {
        return {
            expandedRowKeys: [],
            settingsVisible: false,
            indeterminate: false,
            checkAllColumns: false,
            columnsActive: [],
            tableSizeOptions: [
                { label: 'Маленький', value: 'small' },
                { label: 'Средний', value: 'middle' },
                { label: 'Большой', value: 'large' },
            ],
            observer: null,
            wrapperObserver: null,
            tableScroll: null,
            scroll: {
                x: this.scrollX,
                y: false
            }
        }
    },
    computed: {
        tablesInfo() {
            return this.$store.state.table.tablesInfo
        },
        tableInfo() {
            return this.tablesInfo?.[this.model+this.pageName]
        },
        tableColumns() {
            if(this.tableInfo?.columns?.length)
                return this.tableInfo.columns
            else
                return []
        },
        tableRef() {
            const tableRef = `table_${this.tableType}_${this.model}_${this.pageName}`
            return tableRef
        },
        settingsColumn() {
            const titleName = 'titleWithSettings'
            const settingsColumn = this.tableColumns.find(column => column?.slots?.title === titleName)
            return settingsColumn?.defaultTitle || ''
        },
        tableSize() {
            const defaultSize = 'small'
            const tableSize = this.tableInfo?.table_size

            return tableSize || (this.config?.theme?.tableSize ? this.config.theme.tableSize : defaultSize)
        },
        hasData() {
            return this.rowList?.length
        },
        columnOptions() {
            const availableOptions = []
            this.tableColumns.forEach(column => {
                if(column.hidable)
                    availableOptions.push({
                        label: column.title,
                        value: column.dataIndex,
                        fixed: column.fixed
                    })
            })
            return availableOptions
        },
        tablePageSize() {
            const tableInfo = this.tableInfo
            if(tableInfo?.page_size)
                return tableInfo.page_size
            else
                return null
        },
        columnsVisible() {
            return this.tableColumns.filter(column => column.visible)
        },
    },
    async created() {
        // this.pageSize = this.tablePageSize
        await this.getTableInfo({ 
            page_name: this.pageName, 
            model: this.model ,
            table_type: this.tableType
        })
        // this.settingsColumn()
    },
    methods: {
        ...mapActions({
            getTableInfo: 'table/getTableInfo',
            setTableInfo: 'table/setTableInfo'
        }),
        onColumnCheckboxChange(checkedList) {
            this.indeterminate = !!checkedList.length && checkedList.length < this.columnOptions.length;
            this.checkAllColumns = checkedList.length === this.columnOptions.length;
        },
        onCheckAllChange({target}) {
            if(target.checked)
                this.tableColumns.forEach(column => 
                    column.hidable && this.columnsActive.push(column.dataIndex)
                )
            else
                this.columnsActive = []

            this.indeterminate = false
            this.checkAllColumns = target.checked
        },
        setColumnName(columnValue, newName) {
            const column = this.tableColumns.find(column => column.dataIndex === columnValue)
            column.title = newName
        },
        async setColumns() {
            this.tableColumns.forEach((column, index) => {
                const isActive = this.columnsActive.includes(column.dataIndex)
                const isCustomizable = !!column.hidable
                if(isCustomizable)
                    if(isActive) 
                        this.tableColumns[index].visible = true
                    else 
                        this.tableColumns[index].visible = false
            })
            await this.sendTableInfo()
        },

        async sendTableInfo() {
            const defaultPageSize = 15
            const settings = { 
                columns: this.tableInfo.columns,
                page_size: this.tableInfo.page_size || defaultPageSize,
                ordering: this.tableInfo.ordering
            }
            const params = {
                page_name: this.pageName,
                model: this.model,
                table_type: this.tableType,

                settings: settings 
            }
            try {
                await this.setTableInfo(params)
                await this.$http.post(`table_info/`, params)
            } catch (error) {
                console.error(error)
            }
        },
        columnsActiveInit() {
            this.columnsActive.splice(0)
            this.tableColumns.forEach(column => 
                (column.visible && column.hidable) && this.columnsActive.push(column.dataIndex)
            )
            this.indeterminate = !!this.columnsActive.length && this.columnsActive.length < this.columnOptions.length;
            this.checkAllColumns = this.columnsActive.length === this.columnOptions.length
        },
        async changePageSize(size) {
            // this.reactiveOptions.page = 1
            this.tableInfo.page_size = size

            await this.sendTableInfo()
            await this.updateData()
        },
        async changeSort(sort, sorter) {
            const columnIndex = this.tableColumns.findIndex(column => column.key === sorter.field)
            this.tableColumns.forEach(column => { column.sortOrder && delete column.sortOrder })
            this.tableColumns[columnIndex].sortOrder = sorter.order
            this.tableInfo.ordering = sort
            this.changePage(1)
            // this.reactiveOptions.page = 1
            // console.log(this.reactiveOptions.page)
            try {
                this.updateData()
                await this.sendTableInfo()
            } catch(error) {
                console.error(error)
            }
        },
        scrollTop() {
            document.body.scrollIntoView({ behavior: 'smooth', block: 'start' })
        },
        handleTableChange(pagination, filters, sorter) {
            let params = null

            /*
            const column = this.taskTable[this.taskType].columns.find(column => 
                column.dataIndex === sorter?.column?.dataIndex)
            if(column)
                column.sortOrder = sorter.order
                */
            if(sorter.order) {
                params = `${sorter.order === "ascend" ? '' : '-'}${sorter.field}`
            } else {
                params = null
            }
            this.orderQuery = params

            if(this.hash) {
                let query = Object.assign({}, this.$route.query)
                if(params) {
                    query.ordering = params
                } else {
                    if(query.ordering)
                        delete query.ordering
                }
                this.$router.push({query})
            } else {
                this.changeSort(params, sorter)
            }

            this.scrollTop()
        },

        async dropColumns() {
            const tableName = {
                model: this.model,
                page_name: this.pageName,    
            }
            try {
                const { data } = await this.$http.post('/table_info/', {
                    ...tableName,
                    table_type: this.tableType,
                    drop: true,
                })
                const params = {
                    ...tableName,
                    settings: data 
                }
                await this.setTableInfo(params)
                await this.updateData()
            } catch(error) {
                console.error(error)
            }
        },
        openSettingsModal() {
            this.settingsVisible = true
            this.columnsActiveInit()
        },
        changeTableSize(event) {
            const size = event.target.value
            this.$store.commit('table/SET_TABLE_SIZE', {
                key: this.model+this.pageName,
                data: size
            })
        },
        updateTableRowsHeight() {
            this.$refs[this.tableRef].$children[0].$refs.vcTable.$children[0].handleWindowResize()
        },
        initTableObserver() {
            this.observer = new ResizeObserver(() => {
                this.$nextTick(() => {
                    // console.log(this.tableScroll.clientHeight)
                    this.updateTableRowsHeight()
                })
            })
            this.observer.observe(this.tableScroll)
        },
        initTableWrapperObserver() {
            this.wrapperObserver = new ResizeObserver(() => {
                this.$nextTick(() => {
                    this.updateTableHeight()
                })
            })
            this.wrapperObserver.observe(this.$refs.tableWrapper)
        },
        updateTableHeight() {
            const headerHeight = this.$refs[this.tableRef].$el.querySelector('.ant-table-thead').clientHeight
            const reserve = 10
            const pagerHeight = this.$refs?.Pager?.$el.clientHeight || 0
            this.scroll.y = this.$refs.tableWrapper.parentElement.clientHeight - headerHeight - pagerHeight - reserve
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.tableScroll = this.$refs[this.tableRef].$el.querySelector('.ant-table-scroll .ant-table-tbody') ||
                this.$refs[this.tableRef].$el.querySelector('.ant-table-tbody')
            this.initTableObserver()
            this.initTableWrapperObserver()
        })
        eventBus.$on(`open_table_settings_${this.pageName}`, () => {
            this.openSettingsModal()
        })
    },
    beforeDestroy() {
        if(this.tableScroll) {
            this.observer.unobserve(this.tableScroll)
        }

        if(this.$refs.tableWrapper) {
            this.wrapperObserver.unobserve(this.$refs.tableWrapper)
        }

        eventBus.$off(`open_table_settings_${this.pageName}`)
    }
}
</script>


